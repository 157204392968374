import {WOW} from '../../../node_modules/wowjs/dist/wow';
new WOW({
  offset: 90,
  mobile: false
}).init();


let istextList = localStorage.getItem('istextListstate')

$(document).ready(function(){
  if(istextList == 'false'){
    $('.switch-btn').removeClass('show-text-list')
    $('.switch-list').removeClass('text-list-show')
  }else if(istextList == 'true'){
    $('.switch-btn').addClass('show-text-list')
    $('.switch-list').addClass('text-list-show')
  }

  if(window.location.href.indexOf('#md') != -1){
    if($('.ny-banner').length > 0){
      let headerH = 0
      if($(window).width()> 1024){
        headerH = $('.header').outerHeight() * 0.7272
      }else{
        headerH = $('.header').outerHeight()
      }
      let h = $('.ny-banner').offset().top + $('.ny-banner').outerHeight() - headerH
      $(document).scrollTop(h)
    }
  }
})

$('.switch-btn .img-switch').on('click',function(){
  $(this).closest('.switch-btn').removeClass('show-text-list')
  $('.switch-list').removeClass('text-list-show')
  localStorage.setItem('istextListstate', false);
})
$('.switch-btn .text-switch').on('click',function(){
  $(this).closest('.switch-btn').addClass('show-text-list')
  $('.switch-list').addClass('text-list-show')
  localStorage.setItem('istextListstate', true);
})
